import { HttpResponse } from '@angular/common/http';
import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
import { tap, shareReplay } from 'rxjs/operators';
class NgDocCacheInterceptor {
  constructor() {
    this.cache = new Map();
  }
  static {
    this.TOKEN = Math.random().toString(36).slice(-8);
  }
  intercept(request, next) {
    // Only GET requests can be cached
    if (request.method !== 'GET') {
      return next.handle(request);
    }
    // Do not cache request when the token is not provided
    if (!request.params.has(NgDocCacheInterceptor.TOKEN)) {
      return next.handle(request);
    }
    // Return cached response
    const cachedRequest = this.cache.get(request.url);
    if (cachedRequest) {
      return cachedRequest;
    }
    // Clone the request, delete the TOKEN from the params
    const newRequest = request.clone({
      params: request.params.delete(NgDocCacheInterceptor.TOKEN)
    });
    // Create a new request handler
    const newHandler = next.handle(newRequest).pipe(tap({
      error: event => {
        if (event instanceof HttpResponse) {
          this.cache.delete(event.url || '');
        }
      }
    }), shareReplay(1));
    // Cache the request and return the new handler
    this.cache.set(request.url, newHandler);
    return newHandler;
  }
  static {
    this.ɵfac = function NgDocCacheInterceptor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgDocCacheInterceptor)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgDocCacheInterceptor,
      factory: NgDocCacheInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && void 0;
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NgDocCacheInterceptor };
