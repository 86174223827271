"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isPresent = isPresent;
/**
 *
 * @param value
 */
function isPresent(value) {
  return value !== undefined && value !== null && (typeof value !== 'string' || value !== '');
}
